const suffix = process.env["SUFFIX"] ?? "";

const isLocalhost = process.env["NODE_ENV"] === "development";

let companyInformationUrl;
let frontendErrorlogUrl;
if (isLocalhost) {
  companyInformationUrl = `http://localhost:5153/CompanyInformation/GetCompany`;
  frontendErrorlogUrl = `http://localhost:5153/FrontendErrorLog`;
}
else {
  companyInformationUrl = `https://cloud${suffix}.netvisor.fi/api/salesforce/CompanyInformation/GetCompany`;
  frontendErrorlogUrl = `https://cloud${suffix}.netvisor.fi/api/salesforce/FrontendErrorLog`;
}

export const APPID = process.env["APPID"] ?? "netvisor";
export const API_URL = process.env.API_URL || "http://localhost:5153/graphql";
export const COMPANY_INFORMATION_URL = companyInformationUrl;
export const FRONTEND_ERRORLOG_URL = frontendErrorlogUrl;

export const COMPANY_CREATION_URL = `https://cloud${suffix}.netvisor.fi/api/company-creation`;

let netvisorLoginUrl = "https://suomi.netvisor.fi";

if (isLocalhost) {
  netvisorLoginUrl = "https://jn.nvdev.fi"
}
else if (suffix === "-dev") {
  netvisorLoginUrl = "https://staging.rel.nvdev.fi"
}
else if (suffix === "-stg") {
  netvisorLoginUrl = "https://stage.netvisor.fi"
}

export const NETVISOR_LOGIN_URL = netvisorLoginUrl;

export const strings = {
  ORDER_DATA_COOKIE_NAME: "__nv_cc_params",
  ORDER_ID_COOKIE_NAME: "__order_id",
  USER_FIRSTNAME_COOKIE_NAME: "__nv_user_name",
  CREATION_STATUS_ENUMS: {
    REJECTED: 1,
    READY_FOR_PROCESSING: 2,
    PROCESSING: 3,
    CREATED_SUCCESSFULLY: 4,
    FAILED: 5,
  },
  COMPANY_CREATION_ERROR_TYPES: {
    UNDEFINED: 1,
    COMPANY_ALREADY_EXISTS: 2,
  },
  REFETCH_INTERVAL: 5000,
  TRACKING_LANDINGPAGE: "handl_landing_page",
  TRACKING_ORGANICSOURCE: "organic_source",
  TRACKING_ORGANICSOURCENAME: "organic_source_str",
  TRACKING_TRAFFICSOURCE: "traffic_source",
  TRACKING_FIRSTTRAFFICSOURCE: "first_traffic_source",
  TRACKING_UTMCAMPAIGN: "utm_campaign",
  TRACKING_UTMTERM: "utm_term",
  TRACKING_UTMMEDIUM: "utm_medium",
  TRACKING_UTMSOURCE: "utm_source",
  TRACKING_UTMCONTENT: "utm_content",
};
