import {
  Link,
  Message,
  Typography,
} from "@visma-netvisor/react-component-library";
import { StarFilledIcon } from "@visma-netvisor/nv-react-icons";
import { Trans, useTranslation } from "react-i18next";

export const AdContainerActivated = () => {
  const { t } = useTranslation();

  const newCompanyInfoLink = "https://netvisor.fi/download/netvisor-uudet_yritykset_2024-kampanjan_ehdot.pdf";

  return (
    <Message
      fullWidth
      variant="info"
      iconOverride={<StarFilledIcon />}
    >
      <Typography
        as="h4"
        color="info"
        styleOverrides={{ marginBottom: 8 }}
        variant="headingH4Bold"
      >
        {t("CompanyInformation.AdCampaignActivatedTitle")}
      </Typography>
      <Typography
        as="p"
        color="info"
        styleOverrides={{ marginBottom: 8 }}
        variant="contentHeadingH4Regular"
      >
        {t("CompanyInformation.AdDescription1")}
      </Typography>
      <Typography
        as="p"
        color="info"
        styleOverrides={{ marginBottom: 16 }}
        variant="contentHeadingH4Regular"
      >
        <Trans
          i18nKey="CompanyInformation.AdLink"
          components={[
            <Link
              target="_blank"
              style={{ cursor: "pointer" }}
              href={newCompanyInfoLink}
            ></Link>
          ]}
        />
      </Typography>
      <Typography
        as="p"
        color="info"
        variant="contentHeadingH4Regular"
      >
        {t("CompanyInformation.AdCampaignActivatedText")}
      </Typography>
    </Message>
  );
};

export default AdContainerActivated;
