import {
  Radio,
  Link,
  Layout,
  Message,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";
import { StarFilledIcon } from "@visma-netvisor/nv-react-icons";
import { Trans, useTranslation } from "react-i18next";
import { activateNewCompanyCampaign } from "../../Domain/NewCampaign";

export const AdContainer = () => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState,
  } = useFormContext<FormInputs>();

  const newCompanyInfoLink = "https://netvisor.fi/download/netvisor-uudet_yritykset_2024-kampanjan_ehdot.pdf";

  return (
    <Message
      fullWidth
      variant="info"
      iconOverride={<StarFilledIcon />}
    >
      <Typography
        as="h4"
        color="info"
        styleOverrides={{ marginBottom: 8 }}
        variant="headingH4Bold"
      >
        {t("CompanyInformation.AdTitle")}
      </Typography>
      <Typography
        as="p"
        color="info"
        styleOverrides={{ marginBottom: 8 }}
        variant="contentHeadingH4Regular"
      >
        {t("CompanyInformation.AdDescription1")}
      </Typography>
      <Typography
        as="p"
        color="info"
        styleOverrides={{ marginBottom: 8 }}
        variant="contentHeadingH4Regular"
      >
        <Trans
          i18nKey="CompanyInformation.AdLink"
          components={[
            <Link
              target="_blank"
              style={{ cursor: "pointer" }}
              href={newCompanyInfoLink}
            ></Link>
          ]}
        />
      </Typography>
      <Typography
        as="p"
        color="info"
        styleOverrides={{ marginBottom: 8 }}
        variant="contentHeadingH4Regular"
      >
        {t("CompanyInformation.AdCampaignUseBenefitText")}
      </Typography>
      <Layout.Flex flexDirection="column" flexGap={0}>
        <Radio
          label={t("CompanyInformation.AdChangePacket")}
          name="activate"
          onChange={(e) => {
            e.preventDefault();
            activateNewCompanyCampaign(watch(), formState);
          }}
          value={0}
        ></Radio>
        <Radio
          { ...register("newCompanyCampaignOptOut", { required: true })}
          label={t("CompanyInformation.AdDeclineCampaign")}
          value={1}
        ></Radio>
      </Layout.Flex>
    </Message>
  );
};

export default AdContainer;
